var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fair-product-container"
  }, [_c('div', {
    staticClass: "list-option"
  }, [_vm.showCategory ? _c('div', {
    staticClass: "list-option__primary"
  }, [_c('v-row', [_c('v-col', [_c('category-select', {
    attrs: {
      "return-object": ""
    },
    on: {
      "input": function ($event) {
        _vm.page = 1;
        _vm.search;
      }
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.showAward || _vm.showKeyword ? _c('div', {
    staticClass: "list-option__secondary"
  }, [_c('v-row', [_c('v-col', [_vm.showAward ? _c('v-select', {
    attrs: {
      "items": _vm.awardAts,
      "item-text": "text",
      "item-value": "value",
      "label": _vm.$t('common.선정년도'),
      "dense": "",
      "placeholder": " ",
      "outlined": ""
    },
    on: {
      "input": function ($event) {
        _vm.page = 1;
        _vm.search();
      }
    },
    model: {
      value: _vm.filter.awardAt,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "awardAt", $$v);
      },
      expression: "filter.awardAt"
    }
  }) : _vm._e()], 1), _c('v-col', [_vm.showKeyword ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('common.상품명'),
      "placeholder": " ",
      "hide-details": "",
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-magnify"
    },
    on: {
      "click:append": function ($event) {
        _vm.page = 1;
        _vm.search();
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.page = 1;
        _vm.search();
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "fair-product"
  }, [_c('div', {
    staticClass: "fair-product__head"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "fair-product__body"
  }, [_c('div', {
    staticClass: "fair-product__contents"
  }, [_vm._t("default", function () {
    return _vm._l(_vm.products, function (product) {
      return _c('product-list-item', {
        key: product._id,
        attrs: {
          "product": product,
          "show-select": _vm.showSelect
        }
      });
    });
  }, {
    "products": _vm.products
  })], 2), _vm.showPagination ? _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div v-if="product" class="fair-product__list" target="_blank" @click="!disabled ? $emit('click', product) : false">
        <component :is="tag" @click="showSelect && input()" class="fair-product__list-inner" :to="to">
            <div v-if="showSelect">
                <v-checkbox v-model="selected" :value="product._id" readonly :disabled="10 <= (selected || []).length" @click.prevent="check(product)"></v-checkbox>
            </div>
            <div class="fair-product__list-thumb">
                <img :src="product.thumb" alt="">
            </div>
            <p class="fair-product__list-txt">{{ product.seller.companyName[$i18n.locale] }}</p>
            <p class="fair-product__list-tit">{{ product.name[$i18n.locale] }}</p>
        </component>
    </div>
</template>

<script>
export default {
    props: {
        to: { type: String, default: "" },
        product: { default: null },
        showSelect: { type: Boolean, default: false },
        disabled: Boolean,
        value: { type: Array, default(){ return []; } }
    },
    data(){
        return {
            selected: this.$props.value
        }
    },
    methods: {
        input(){
            if(this.check()){
                let index = this.selected.indexOf(this.product._id);
                (0 <= index) ? this.selected.splice(index, 1) : this.selected.push(this.product._id);
                this.$emit("input", this.selected);
            }
        },
        check(){
            try{
                if(10 <= this.selected?.length && !this.selected.includes(this.product._id) ) throw new Error("최대 10개 상품만 신청 가능합니다");
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        }
    },
    computed: {
        tag(){
            return this.to ? "router-link" : "a";
        }
    },
    watch: {
        value(){
            this.selected = this.value;
        }
    }
}
</script>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fair-product-category"
  }, _vm._l(_vm.maxDepth, function (depth) {
    return _c('div', {
      key: depth,
      staticClass: "fair-product-category__col"
    }, [_c('v-select', {
      attrs: {
        "items": [_vm.nothing].concat(_vm.getCategories(depth)),
        "item-text": `name.${_vm.$i18n.locale}`,
        "item-value": "_id",
        "value": _vm.values[depth - 1] || null,
        "return-object": "",
        "dense": "",
        "outlined": ""
      },
      on: {
        "input": function (selected) {
          return _vm.input(_vm.findCategory(selected._id || _vm.values[depth - 2]));
        }
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
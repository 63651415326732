<template>
    <div class="fair-list">
        <div class="fair-list__body">
            <router-link :to="to" class="fair-list__thumb">
                <img :src="fair.thumb[$i18n.locale]" alt="">
            </router-link>
            <div class="fair-list__contents">
                <em :class="{ [`color-${color}`]: true }" class="badge">{{fair.title[$i18n.locale]}}</em>
                <router-link :to="to" class="tit">{{ fair.name[$i18n.locale] }}</router-link>
                <p>{{ $dayjs(fair.open.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(fair.open.endedAt).format("YYYY-MM-DD") }}</p>
            </div>
        </div>
        <div class="fair-list__buttons">
            <router-link :to="to" class="button button--round button--border-light"><span>{{$t("common.자세히 보기")}}</span></router-link>
            <a v-if="scope.includes('seller') && fair.applicationStatus == '접수가능' && !fair.products.find(({ _seller }) => payload._user == _seller)" @click.prevent="$emit('apply', fair)" class="button button--round button--primary" href="#"><span>신청하기</span></a>
            <a v-if="scope.includes('seller') && fair.products.find(({ _seller }) => payload._user == _seller)" class="button button--round button--pink" href="#" onclick="event.preventDefault()"><span>참여중</span></a>
            <a v-if="fair.status == '종료'" class="button button--round button--grey disabled" href="#" aria-disabled="true" onclick="event.preventDefault()"><span>종료</span></a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: { default: "basic" },
        fair: { default: null },
        color: { default: "primary" },
        to: { default: '' }
    },
    computed: {
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
}
</script>

<template>
    <div class="fair-product-container">
        <!-- 참여제품 카테고리 선택 -->
        <div class="list-option">
            <div v-if="showCategory" class="list-option__primary">
                <v-row>
                    <v-col>
                        <category-select v-model="category" return-object @input="page = 1; search"></category-select>
                    </v-col>
                </v-row>
            </div>
            <div v-if="showAward || showKeyword" class="list-option__secondary">
                <v-row>
                    <v-col>
                        <v-select v-if="showAward" v-model="filter.awardAt" :items="awardAts" item-text="text" item-value="value" :label="$t('common.선정년도')" dense placeholder=" " outlined @input="page = 1; search()"></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-if="showKeyword" v-model="filter.searchValue" :label="$t('common.상품명')" placeholder=" " hide-details dense outlined append-icon="mdi-magnify" @click:append="page = 1; search();" @keydown.enter="page = 1; search();"></v-text-field>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="fair-product">
            <div class="fair-product__head">
                <slot name="title"></slot>
            </div>
            <div class="fair-product__body">
                <div class="fair-product__contents">
                    <slot v-bind:products="products">
                        <product-list-item v-for="product in products" :key="product._id" :product="product" :show-select="showSelect"></product-list-item>
                    </slot>
                </div>

                <!-- 페이지네이션 -->
                <pagination-component v-if="showPagination" v-model="page" :count="pageCount" @input="search"></pagination-component>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

import CategorySelect from "@/components/client/fair/category-select.vue";
import ProductListItem from "@/components/client/fair/product-list-item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import FairListItem from './fair-list-item.vue';

export default {
    components: {
        CategorySelect,
        ProductListItem,
        PaginationComponent,
        FairListItem,
    },
    props: {
        fair: { default: null },
        type: { type: String, default: "fairs" },
        showFilter: Boolean,
        showCategory: Boolean,
        showAward: Boolean,
        showKeyword: Boolean,
        showSelect: Boolean,
        showPagination: Boolean
    },
    data(){
        return {
            category: null,

            page: 1,
            pageCount: 0,
            limit: 20,
            
            filter: {
                awardAt: null,
                searchKey: "name",
                searchValue: null,
                ...this.$route.query,
                ...this.$attrs
            },

            products: [],
            awardAts: [
                { text: this.$t("common.select_all"), value: null },
                { text: "2021", value: 2021 },
                { text: "2020", value: 2020 },
                { text: "2019", value: 2019 },
                { text: "2018", value: 2018 },
            ]

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }
            
            switch(this.type){
                case "fairs": {
                    let { summary, products } = await api.v1.fairs.products.gets(
                        this.fair._id, 
                        { 
                            headers: { 
                                skip: (this.page - 1) * this.limit,
                                limit: this.limit
                            },
                            params: {
                                _categories: this._categories,
                                ...this.filter
                            }
                        }
                    );
                    this.products = products;
                    this.pageCount = Math.ceil(summary.totalCount / this.limit);
                    break;
                }
                case "me": {
                    let { summary, products } = await api.v1.me.products.gets({ 
                        headers: { 
                            skip: (this.page - 1) * this.limit, 
                            limit: this.limit
                        }, 
                        params: { 
                            _categories: this._categories, 
                            ...this.filter
                        }
                    });

                    this.products = products;
                    this.pageCount = Math.ceil(summary.totalCount / this.limit)
                    break;
                }
                case "likes": {
                    let { likes } = await api.v1.me.likes.gets({ headers: { limit: 0 }, params: this.filter });
                    this.products = likes.map(like => like.product);
                    break;
                }
            }

            this.products = this.products.filter(product => !this._categories.length || this._categories.includes(product._category));
        }
    },
    computed: {
        _categories(){
            return this.category ? [ this.category._id, ...this.category.children.map(category => category._id)] : [];
        }
    },
    watch: {
        "$attrs"(){
            this.filter = {
                ...this.filter,
                ...this.$attrs
            };

            // this.page = 1;
            this.search(true);
        },
        category(){
            this.page = 1;
            this.search(true);
            this.$forceUpdate();
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.list-option{
    &__primary { padding: 24px 20px; background: #f9f9f9; border-radius: 10px; }
    &__primary .col { padding-top: 0; padding-bottom: 0; }
    &__secondary { padding: 24px 20px; }
    &__secondary .col { padding-top: 0; padding-bottom: 0; }
    &__secondary .col:last-child { display: flex; flex-direction: column; align-items: flex-end; }
    &__secondary .v-input { max-width: 280px; }
}
::v-deep .fair-product{
    &__head{
        display: flex; justify-content: space-between; padding: 20px 0; font-size: 14px; color: #999999;

        .tit { line-height: 1; font-size: 18px; }
    }
    &__body { border-top: 1px solid #999999; }
    &__contents { display: flex; flex-wrap: wrap; margin: 0 -12px; }
    &__list { flex: 0 0 20%; padding: 22px 12px; width: 20%; font-size: 14px; color: #999999; transition: all ease .5s; }
    &__list-thumb { overflow: hidden; position: relative; margin-bottom: 25px; padding-top: 100%; width: 100%; height: 0; border: 1px solid $border; border-radius: 15px; }
    &__list-thumb img { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; transition: all ease .5s; }
    &__list-txt { @include line-clamp(1); margin: 0 10px; line-height: 1; font-size: 16px; color: #666; }
    &__list-tit { @include line-clamp(2); margin: 10px 10px 0; font-size: 18px; color: #222; font-weight: 700; }
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}
@media(min-width:1201px){
    .fair-product{
       ::v-deep  &__list-inner:hover &__list-thumb img { transform: scale(1.1); }   
    }
}
@media(max-width:1200px){
    ::v-deep .fair-product{
        &__head{
            .tit { line-height: 1; font-size: 17px; }
        }
        &__list { flex: 0 0 25%; width: 25%; }
        &__list-txt { font-size: 15px; }
        &__list-tit { font-size: 17px; }
    }
}
@media(max-width:768px){
    .list-option{
        &__secondary { padding: 12px 0 0; }
        &__secondary .v-input { max-width: none; }
        &__secondary .col { flex-basis: 100%; padding-top: 4px; padding-bottom: 4px; }
        &__secondary .col:last-child { flex-direction: row; }
    }
    ::v-deep .fair-product{
        &__head{
            font-size: 13px;

            .tit { line-height: 1; font-size: 16px; }
        }
        &__contents { margin: 0 -5px; }
        &__list { flex: 0 0 50%; padding: 25px 5px; width: 50%; font-size: 13px; }
        &__list-thumb { margin-bottom: 20px; }
        &__list-txt { font-size: 13px; }
        &__list-tit { font-size: 15px; }
    }
}
</style>
<template>
    <div class="fair-product-category">
        <div v-for="depth in maxDepth" :key="depth" class="fair-product-category__col">
            <v-select
            :items="[nothing, ...getCategories(depth)]"
            :item-text="`name.${$i18n.locale}`"
            item-value="_id"
            :value="values[depth - 1] || null"
            return-object
            dense
            outlined
            @input="selected => input(findCategory(selected._id || values[depth-2]))"
            ></v-select>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { default: null },
        returnObject: { type: Boolean, default: false }
    },
    data() {
        return{
            c1: '전체',
            c2: '전체',
            cat1: ['전체', '문구', '취미', '자동차', '애완'],
            cat2: ['전체', '청소', '생활', '위생'],

            categories: [],
            nothing: {
                _id: null,
                name: {
                    ko: ":: 전체 ::",
                    en: ":: all ::",
                    cn: ":: all ::"
                },
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: { 
        input(category){
            this.$emit("input", this.returnObject ? category : category?._id);
        },
        async init(){
            let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },

        findCategory(value, categories = this.categories){
            for(let category of categories){
                if(category._id == value || category._id == value?._id) return category;
                if(category.children){
                    let result = this.findCategory(value, category.children);
                    if(result) return result;
                }
            }
            return null;
        },

        getCategories(depth){
            let categories = this.categories;
            for(let i=1; i < depth; i++){
                categories = categories.find(category => category._id == this.values[i - 1] )?.children || [];
            }
            return categories;
        },

        getDepth(categories = this.categories){
            let depth = 0;
            for(let category of categories){
                if(depth < category.depth) depth = category.depth;
                if(category.children){
                    let result = this.getDepth(category.children);
                    if(depth < result) depth = result;
                }
            }
            return depth;
        }
    },
    computed: {
        maxDepth(){
            return this.getDepth();
        },
        category(){
            return this.findCategory(this.value);
        },
        values(){
            return [...(this.category?._parents || []), this.category?._id];
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.fair-product-category{
    display: flex;

    .v-select { max-width: 280px;}
    ::v-deep .v-input__slot { margin-bottom: 0; background-color: #fff; }

    &__col:not(:last-child) { position: relative; padding-right: 45px; }
    &__col:not(:last-child)::after {
        content: ''; position: absolute; top: 0; right: 0; bottom: 0;
        margin: auto; width: 45px; height: 45px; background: url('/images/icon/category-arrow-right.png') no-repeat center / auto 10px;
    }
}
@media(max-width:1200px){
    
}
@media(max-width:768px){
    .fair-product-category{
        flex-direction: column;

        .v-input { font-size: 14px; }
        .v-select { max-width: none; }
        &__col { padding-top: 4px; padding-bottom: 4px; padding-right: 35px; }
        &__col:not(:last-child) { padding-right: 35px; }
        &__col:not(:last-child)::after { width: 35px; height: 35px; }
    }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product ? _c('div', {
    staticClass: "fair-product__list",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        !_vm.disabled ? _vm.$emit('click', _vm.product) : false;
      }
    }
  }, [_c(_vm.tag, {
    tag: "component",
    staticClass: "fair-product__list-inner",
    attrs: {
      "to": _vm.to
    },
    on: {
      "click": function ($event) {
        _vm.showSelect && _vm.input();
      }
    }
  }, [_vm.showSelect ? _c('div', [_c('v-checkbox', {
    attrs: {
      "value": _vm.product._id,
      "readonly": "",
      "disabled": 10 <= (_vm.selected || []).length
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.check(_vm.product);
      }
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "fair-product__list-thumb"
  }, [_c('img', {
    attrs: {
      "src": _vm.product.thumb,
      "alt": ""
    }
  })]), _c('p', {
    staticClass: "fair-product__list-txt"
  }, [_vm._v(_vm._s(_vm.product.seller.companyName[_vm.$i18n.locale]))]), _c('p', {
    staticClass: "fair-product__list-tit"
  }, [_vm._v(_vm._s(_vm.product.name[_vm.$i18n.locale]))])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
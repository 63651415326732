var render = function render(){
  var _class;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fair-list"
  }, [_c('div', {
    staticClass: "fair-list__body"
  }, [_c('router-link', {
    staticClass: "fair-list__thumb",
    attrs: {
      "to": _vm.to
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.fair.thumb[_vm.$i18n.locale],
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "fair-list__contents"
  }, [_c('em', {
    staticClass: "badge",
    class: (_class = {}, _class[`color-${_vm.color}`] = true, _class)
  }, [_vm._v(_vm._s(_vm.fair.title[_vm.$i18n.locale]))]), _c('router-link', {
    staticClass: "tit",
    attrs: {
      "to": _vm.to
    }
  }, [_vm._v(_vm._s(_vm.fair.name[_vm.$i18n.locale]))]), _c('p', [_vm._v(_vm._s(_vm.$dayjs(_vm.fair.open.startedAt).format("YYYY-MM-DD")) + " ~ " + _vm._s(_vm.$dayjs(_vm.fair.open.endedAt).format("YYYY-MM-DD")))])], 1)], 1), _c('div', {
    staticClass: "fair-list__buttons"
  }, [_c('router-link', {
    staticClass: "button button--round button--border-light",
    attrs: {
      "to": _vm.to
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.자세히 보기")))])]), _vm.scope.includes('seller') && _vm.fair.applicationStatus == '접수가능' && !_vm.fair.products.find(function (_ref) {
    var _seller = _ref._seller;
    return _vm.payload._user == _seller;
  }) ? _c('a', {
    staticClass: "button button--round button--primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('apply', _vm.fair);
      }
    }
  }, [_c('span', [_vm._v("신청하기")])]) : _vm._e(), _vm.scope.includes('seller') && _vm.fair.products.find(function (_ref2) {
    var _seller = _ref2._seller;
    return _vm.payload._user == _seller;
  }) ? _c('a', {
    staticClass: "button button--round button--pink",
    attrs: {
      "href": "#",
      "onclick": "event.preventDefault()"
    }
  }, [_c('span', [_vm._v("참여중")])]) : _vm._e(), _vm.fair.status == '종료' ? _c('a', {
    staticClass: "button button--round button--grey disabled",
    attrs: {
      "href": "#",
      "aria-disabled": "true",
      "onclick": "event.preventDefault()"
    }
  }, [_c('span', [_vm._v("종료")])]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }